import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Aarrow from '../assets/svg/arquetipo-arrow.svg'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div id='success'>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link className='cta' to='/'>Back Home<Aarrow/></Link>
    </div>
  </Layout>
)

export default NotFoundPage
